import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { SelectButton, DropdownItem } from '@weezevent/nacre';
export function DropdownActionAttendee({
    selectedAttendees,
    onSendMail,
    onDownloadTicket,
    onModify,
    setShowDeleteModal,
    onReceiveMail,
    onChangeAttendeesDuplicated,
    setAddAttendee,
    googleAnalytics,
    disableDeletionSelection,
    setSelectedAttendees,
    disabled
}) {
    const { t } = useTranslation();

    const disableSendMail = React.useMemo(() => {
        return selectedAttendees.some((att) => !att.send_mail);
    }, [selectedAttendees]);

    const disableDownloadTicket = React.useMemo(() => {
        return selectedAttendees.some((att) => Boolean(!att.ticket_url));
    }, [selectedAttendees]);

    if (selectedAttendees.length === 1) {
        let attendee = selectedAttendees[0];
        return (
            <SelectButton
                tooltipTitle={t('partners.invitation.table.noActionTooltip')}
                title={selectedAttendees.length > 0 ? t('partners.invitation.selected_attendees', { count: selectedAttendees.length }) : t('ticket.common.action.bulk')}
                objects={selectedAttendees}
                disabled={disabled}
            >
                {attendee.send_mail && (
                    <DropdownItem
                        item={t('partners.invitation.table.send_mail')}
                        onClick={() => {
                            onSendMail([attendee]);
                            googleAnalytics?.event({
                                category: 'Guest list',
                                action: 'Global actions - Send invitations',
                                label: 'Send invitations',
                                value: selectedAttendees.length
                            });
                        }}
                    />
                )}
                <DropdownItem
                    item={t('partners.invitation.table.invite_again')}
                    onClick={() => {
                        setAddAttendee(true);
                        onChangeAttendeesDuplicated([attendee]);
                        googleAnalytics?.event({
                            category: 'Guest list',
                            action: 'Global actions - Invite Again',
                            label: 'Invite Again'
                        });
                    }}
                />
                <DropdownItem.Line />
                {Boolean(attendee.ticket_url) && (
                    <DropdownItem
                        item={t('partners.invitation.table.download_ticket')}
                        onClick={() => {
                            onDownloadTicket([attendee]);
                            googleAnalytics?.event({
                                category: 'Guest list',
                                action: 'Global actions - Download invitations',
                                label: 'Download invitations',
                                value: selectedAttendees.length
                            });
                        }}
                    />
                )}
                {attendee.send_mail && onReceiveMail && <DropdownItem item={t('partners.invitation.table.receive_mail')} onClick={() => onReceiveMail([attendee], true)} />}
                <DropdownItem
                    item={t('ticket.common.action.edit')}
                    onClick={() => {
                        onModify(attendee);
                        googleAnalytics?.event({
                            category: 'Guest list',
                            action: 'Global actions - Edit guest',
                            label: 'Edit guest'
                        });
                    }}
                />
                <DropdownItem.Line />
                <DropdownItem
                    disabled={disableDeletionSelection}
                    item={t('partners.invitation.table.delete_attendee')}
                    onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedAttendees([attendee]);
                    }}
                />
            </SelectButton>
        );
    }

    return (
        <SelectButton
            tooltipTitle={t('partners.invitation.table.noActionTooltip')}
            title={
                Object.values(selectedAttendees).length > 0
                    ? t('partners.invitation.selected_attendees', { count: Object.values(selectedAttendees).length })
                    : t('ticket.common.action.bulk')
            }
            objects={selectedAttendees}
        >
            <DropdownItem
                item={t('partners.invitation.table.invite_again')}
                onClick={() => {
                    setAddAttendee(true);
                    onChangeAttendeesDuplicated(selectedAttendees);
                    googleAnalytics?.event({
                        category: 'Guest list',
                        action: 'Global actions - Invite Again',
                        label: 'Invite Again'
                    });
                }}
            />
            {!disableSendMail && (
                <DropdownItem
                    item={t('partners.invitation.table.send_batch_mail')}
                    onClick={() => {
                        onSendMail(selectedAttendees);
                        googleAnalytics?.event({
                            category: 'Guest list',
                            action: 'Global actions - Send invitations',
                            label: 'Send invitations',
                            value: selectedAttendees.length
                        });
                    }}
                />
            )}
            {!disableDownloadTicket && (
                <DropdownItem
                    item={t('partners.invitation.table.download_batch_ticket')}
                    onClick={() => {
                        onDownloadTicket(selectedAttendees);
                        googleAnalytics?.event({
                            category: 'Guest list',
                            action: 'Global actions - Download invitations',
                            label: 'Download invitations',
                            value: selectedAttendees.length
                        });
                    }}
                />
            )}
            {!disableSendMail && onReceiveMail && <DropdownItem item={t('partners.invitation.table.receive_batch_mail')} onClick={() => onReceiveMail(selectedAttendees, true)} />}
            <DropdownItem
                disabled={disableDeletionSelection}
                item={t('partners.invitation.table.delete_attendee', { count: selectedAttendees.length })}
                onClick={() => {
                    setShowDeleteModal(true);
                    setSelectedAttendees(selectedAttendees);
                }}
            />
        </SelectButton>
    );
}

DropdownActionAttendee.propTypes = {
    selectedAttendees: PropTypes.array.isRequired,
    onSendMail: PropTypes.func.isRequired,
    onDownloadTicket: PropTypes.func.isRequired,
    onModify: PropTypes.func.isRequired,
    onReceiveMail: PropTypes.func,
    setShowDeleteModal: PropTypes.func.isRequired,
    onChangeAttendeesDuplicated: PropTypes.func.isRequired,
    setAddAttendee: PropTypes.func.isRequired,
    googleAnalytics: PropTypes.object.isRequired,
    disableDeletionSelection: PropTypes.bool.isRequired,
    setSelectedAttendees: PropTypes.func.isRequired
};
