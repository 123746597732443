import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import Styles from '../../styles/components/invitations/attendees-list.module.css';

import { useTranslation } from 'react-i18next';
import { Table, ToastContext, Dropdown, Button, Card, ContentCard, HeaderCard, TitleCard, Paginator, Icon, Modal, FilterSetController, Filter } from '@weezevent/nacre';
import AttendeeSideBar from './attendeeSideBar';

import * as Sentry from '@sentry/browser';
import { Loader } from 'semantic-ui-react';
import { fetcher } from '@weezevent/core';
import DropdownPageSize from '../ticketing/dropdown-pagesize/dropdownPageSize';
import { DropdownActionAttendee } from './dropdownActionAttendee';
import AttendeeRow from './attendeeRow';
import { AttendeeCreationDrawer } from './attendeeCreationDrawer/attendeeCreationDrawer';
import { emptyGuest } from './attendeeCreationDrawer/state';

const languages = ['fr-fr', 'fr-ca', 'en-gb', 'es-es', 'nl-nl', 'de-de', 'ca-es', 'it', 'pt-pt', 'br', 'eu', 'en-us', 'en-ca', 'ru-ru', 'el-gr', 'tr-tr'];

export function isActionDisabled(conditions, attendee) {
    if (conditions.downloaded_tickets && conditions.sent_tickets && conditions.scanned_tickets) {
        return false;
    }

    const downloaded_tickets = !conditions.downloaded_tickets ? attendee.downloads_count > 0 : false;
    const sent_tickets = !conditions.sent_tickets ? Boolean(attendee.mail_trackers?.length) : false;
    const scanned_tickets = !conditions.scanned_tickets ? attendee.scanned === true : false;

    return downloaded_tickets || sent_tickets || scanned_tickets;
}

export function DeleteAttendeeModal({ showDeleteModal, setShowDeleteModal, handleDeleteAttendee, attendees, partner, organizationId, isDeleting }) {
    const { t } = useTranslation();

    const filteredAttendees = React.useMemo(() => {
        const ticket_deletion_conditions = partner.settings?.attendees_management?.ticket_deletion_conditions;

        if (organizationId) {
            return attendees;
        }

        if (!ticket_deletion_conditions) {
            return attendees.filter((attendee) => !attendee.scanned);
        }

        return attendees.filter((attendee) => !isActionDisabled(ticket_deletion_conditions, attendee));
    }, [attendees, partner, organizationId, isActionDisabled]);

    return (
        <Modal
            size="large"
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            title={filteredAttendees.length !== attendees.length ? t('partners.invitation.table.delete_modale_information') : t('partners.invitation.table.delete_modale_title')}
            submitButton={<Button primary onClick={() => handleDeleteAttendee(filteredAttendees)} label={t('partners.common.cta.yes')} isLoading={isDeleting} />}
            cancelButton={<Button onClick={() => setShowDeleteModal(false)} label={t('partners.common.cta.no')} disabled={isDeleting} />}
        >
            {filteredAttendees.length !== attendees.length
                ? t('partners.invitation.table.delete_modale_body_filtered')
                : t('partners.invitation.table.delete_modale_body', { count: attendees.length })}
        </Modal>
    );
}

DeleteAttendeeModal.propTypes = {
    showDeleteModal: PropTypes.bool.isRequired,
    setShowDeleteModal: PropTypes.func.isRequired,
    handleDeleteAttendee: PropTypes.func.isRequired,
    attendees: PropTypes.array.isRequired
};

function handleSearchQuery(filters) {
    let query = {};

    Object.entries(filters).forEach(([key, value]) => {
        //Specific case for rates because rate and rate_template
        if (key === 'rates') {
            let types = { rate: [], rate_template: [] };
            Array.from(value).forEach((rate) => {
                let [type, id] = rate.split(':');
                types[type].push(id);
            });
            if (types['rate']?.length > 0) {
                query.booking__rate__in = types['rate'].join(',');
            }
            if (types['rate_template']?.length > 0) {
                query.rate_template__in = types['rate_template'].join(',');
            }
            return;
        }

        if (value instanceof Set) {
            query[key] = Array.from(value).join(',');
            return;
        }

        query[key] = value;
    });

    return query;
}

export function AttendeesList({
    partner,
    addAttendee,
    setAddAttendee,
    formModel,
    onAttendeeInProgress,
    inventoryModel,
    user,
    attendeeModel,
    rateModel,
    batchStatsModel,
    attendeeCreationRequestModel,
    bookingModel,
    mailStatusModel,
    googleAnalytics,
    progressGuestsModel,
    organizationId = null
}) {
    const { t } = useTranslation();
    const locale = i18n.language;
    const [waiting_guests, setWaitingGuests] = React.useState(0);

    // list of attendees to duplicate
    const [attendeesDuplicated, setAttendeesDuplicated] = React.useState(null);

    const [partnerForm, setPartnerForm] = React.useState([]);

    const userLanguage = React.useMemo(() => {
        // In some instances or browsers, locale contains only two letters like "fr", thus not matching
        // Pyvar's formPartner's translations.
        // This is Memo is used to ensure that consistent datas are always passed to AttendeeTable,
        // And provides a fallback if necessary.
        const language = locale.toLowerCase().replace('_', '-');
        return languages.find((lg) => lg.includes(language)) || 'en-gb';
    }, [locale]);

    /** Trigger first fetch progress in order to have the info banner **/
    const prefetchProgress = React.useCallback(() => {
        progressGuestsModel
            .getAll({ partnerId: partner.pk, organizationId, cache: false })
            .then((response) => {
                setWaitingGuests(response.waiting_guests);
                onAttendeeInProgress(response.waiting_guests);
            })
            .catch((e) => {
                console.error(e);
                Sentry.captureException(e);
            });
    }, [partner.pk, onAttendeeInProgress, progressGuestsModel, organizationId]);

    const hasHandledInventoriesLoaded = React.useRef(false);

    const filtersFirstLoading = React.useCallback(
        (response) => {
            if (!hasHandledInventoriesLoaded.current) {
                prefetchProgress();
                let defaultFilters = { event_time_status: new Set(['upcoming', 'ongoing']) };

                let inventoriesfilters = response.filter((inventory) => inventory.active).map((inventory) => inventory.uuid);
                if (inventoriesfilters.length > 0) {
                    defaultFilters = { ...defaultFilters, booking__inventory__in: new Set(inventoriesfilters) };
                }

                setFilters(() => {
                    return defaultFilters;
                });

                hasHandledInventoriesLoaded.current = true;
            }
        },
        [prefetchProgress, hasHandledInventoriesLoaded]
    );

    const [inventories, loadingInventories] = inventoryModel.useApiModel({
        partnerId: partner.pk,
        query: {
            include_inactive: true
        },
        onSuccess: (response) => {
            filtersFirstLoading(response);
        },
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
            toast.error(t('partners.invitation.table.error'));
        }
    });

    const [rates, loadingRates] = rateModel.useApiModel({
        partnerId: partner.pk,
        query: {
            include_unavailable: true
        },
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
            toast.error(t('partners.invitation.table.error'));
        }
    });

    const [, loadingPartnerForm] = formModel.useApiModel(
        {
            organizationId: partner.organization,
            partnerId: partner.pk,
            onError: (e) => {
                console.error(e);
                Sentry.captureException(e);
                toast.error(t('partners.invitation.table.error'));
            },
            onSuccess: (data) => {
                let pform = { ...data };
                if (pform.form.weez_id && pform.form.fields.length > 0) {
                    let fields = pform.form.fields
                        .filter((fd) => fd.usage === 'attendee')
                        .map((d) => {
                            let id = d.form_field.weez_id.replace('-', '_');
                            d.form_field.weez_id = id;
                            return d;
                        })
                        .sort(function (a, b) {
                            return a.order - b.order;
                        });
                    setPartnerForm(fields);
                }
            }
        },
        [partner]
    );

    const hasPartnerForm = React.useMemo(() => {
        return Boolean(partnerForm.length);
    }, [partnerForm]);

    const [filters, setFilters] = React.useState({});
    const [pageSize, setPageSize] = React.useState(50);
    const [selectedAttendees, setSelectedAttendees] = React.useState([]);
    const [attendeeQuickEdit, setAttendeeQuickEdit] = React.useState(null);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selectedAll, setSelectedAll] = React.useState(false);

    const toast = React.useContext(ToastContext);

    const [attendees, loading, sync, error] = attendeeModel.useApiModel(
        {
            partnerId: partner.pk,
            onError: (e) => {
                console.error(e);
                Sentry.captureException(e);
                toast.error(t('partners.invitation.table.error'));
            },
            launch: hasHandledInventoriesLoaded.current,
            query: {
                limit: pageSize,
                offset: currentPage * pageSize,
                ...handleSearchQuery(filters)
            }
        },
        [pageSize, currentPage, filters, hasHandledInventoriesLoaded.current]
    );

    const onAttendeeSelected = React.useCallback(
        (attendee, selected) => {
            let alreadySelected = selectedAttendees.findIndex((att) => att.pk === attendee.pk);
            let newSelected = [...selectedAttendees];

            if (alreadySelected > -1) {
                if (!selected) {
                    // Remove
                    newSelected.splice(alreadySelected, 1);
                } else {
                    // Add
                    newSelected.push(attendee);
                }
            } else {
                if (selected) {
                    newSelected.push(attendee);
                }
            }

            setSelectedAttendees(newSelected);
        },
        [selectedAttendees]
    );

    const selectAll = React.useCallback(
        ({ event, data: { checked } }) => {
            if (!checked) {
                setSelectedAttendees([]);
            } else {
                setSelectedAttendees(attendees.map((attendee) => attendee));
            }
            setSelectedAll(checked);
        },
        [attendees]
    );

    const downloadTicket = React.useCallback(
        (attendees = []) => {
            // This action donwload tickets on selected attendees
            if (attendees.length === 0) {
                toast.error(t('partners.invitation.download_ticket_error_no_select'));
                return null;
            }
            let attendees_id = attendees.map((attendee) => attendee.pk).join(',');
            const path = organizationId
                ? `/ticket/organizations/${organizationId}/partners/${partner.pk}/guests/download-tickets?attendees=${attendees_id}`
                : `/ticket/partners/${partner.pk}/guests/download-tickets?attendees=${attendees_id}`;
            fetcher
                .get(path)
                .then(({ response }) => {
                    window.open(response);
                    window.focus();
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    toast.error(t('partners.invitation.download_ticket_error'));
                });
        },
        [partner.pk, toast, t]
    );

    const sendMail = React.useCallback(
        (attendees = [], toUser = false) => {
            // This action send mail on selected attendees
            if (attendees.length === 0) {
                toast.error(t('partners.invitation.send_mail.error_no_select'));
                return null;
            }
            let attendees_id = attendees.map((attendee) => attendee.pk);
            let data = { attendees: attendees_id };
            if (toUser) {
                const { email, name } = user;
                data.to = { email, name };
            }
            const path = organizationId ? `/ticket/organizations/${organizationId}/partners/${partner.pk}/guests/send-mail` : `/ticket/partners/${partner.pk}/guests/send-mail`;
            fetcher
                .post(path, { data })
                .then(() => {
                    sync();
                    toast.success(t('partners.invitation.send_mail.success'));
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    toast.error(t('partners.invitation.send_mail.error'));
                });
        },
        [partner.pk, toast, t, user, sync]
    );

    const handleDeleteAttendee = React.useCallback(
        (attendees = []) => {
            setIsDeleting(true);
            // This action delete attendee on confirm.
            if (attendees.length !== 1) {
                let data = attendees.map((attendee) => attendee.pk);
                attendeeModel
                    .bulk_delete({ organizationId, partnerId: partner.pk, data })
                    .then(() => {
                        sync();
                        setSelectedAttendees([]);
                        setShowDeleteModal(false);
                        setIsDeleting(false);
                        toast.success(t('partners.invitation.table.delete_attendees_success'));
                    })
                    .catch((e) => {
                        console.error(e);
                        Sentry.captureException(e);
                        toast.error(t('partners.invitation.table.delete_attendees_error'));
                    });

                return null;
            }

            let attendee = attendees[0];
            attendee
                .delete({ partnerId: partner.pk })
                .then(() => {
                    sync();
                    onAttendeeSelected(attendee, false);
                    setShowDeleteModal(false);
                    setIsDeleting(false);
                    toast.success(t('partners.invitation.table.delete_attendee_success'));
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    toast.error(t('partners.invitation.table.delete_attendee_error'));
                });
        },
        [sync, partner, setShowDeleteModal, t, toast, onAttendeeSelected, setSelectedAttendees]
    );

    const disableDeletionSelection = React.useMemo(() => {
        const ticket_deletion_conditions = partner.settings?.attendees_management?.ticket_deletion_conditions;
        if (organizationId) {
            return false;
        }
        if (!ticket_deletion_conditions) {
            return selectedAttendees.every((attendee) => attendee.scanned);
        }
        return selectedAttendees.every((attendee) => {
            return isActionDisabled(ticket_deletion_conditions, attendee);
        });
    }, [partner, selectedAttendees, organizationId, isActionDisabled]);

    React.useEffect(() => {
        let timer = setInterval(() => {
            progressGuestsModel
                .getAll({ partnerId: partner.pk, organizationId, cache: false })
                .then((response) => {
                    if (waiting_guests !== response.waiting_guests) {
                        if (response.waiting_guests < waiting_guests) {
                            sync();
                            onAttendeeInProgress(response.waiting_guests);
                        }
                    }
                    setWaitingGuests(response.waiting_guests);
                    onAttendeeInProgress(response.waiting_guests);
                })
                .catch((e) => {
                    console.error(e);
                    if (e.code === 403) {
                        clearInterval(timer);
                    } else {
                        Sentry.captureException(e);
                    }
                });
        }, 5000);
        return () => clearInterval(timer);
    }, [partner, setWaitingGuests, waiting_guests, onAttendeeInProgress, sync, progressGuestsModel, organizationId]);

    const onChangeSearch = React.useCallback(
        (value) => {
            let tmpFilters = { ...filters };
            if (value !== '' && value !== null) {
                tmpFilters = { ...tmpFilters, search: value };
            } else {
                delete tmpFilters.search;
            }
            setFilters(tmpFilters);
        },
        [filters]
    );

    const onChangeFilters = (values) => {
        //Conserve filter.search during onChange
        if (filters.search !== undefined && filters.search.length > 0) {
            values.search = filters.search;
        }
        //Delete filter if size of Set === 0
        Object.entries(values).forEach(([key, value]) => {
            if (value instanceof Set && value.size === 0) {
                delete values[key];
            }
        });

        setFilters(values);
    };

    const optionsRates = React.useMemo(() => {
        //We want to display in the filter rates without rate_template
        //and rates_templates from rate only once each
        const rates_without_template = rates?.filter((rate) => !rate.rate_template) || [];
        // Allow to keep track of the rate_templates and display only each rate_template once
        let unique_rates = new Set();
        const rates_with_templates =
            rates?.filter((rate) => {
                if (!rate.rate_template) {
                    return false;
                }
                if (unique_rates.has(rate.rate_template.uuid)) {
                    return false;
                }
                unique_rates.add(rate.rate_template.uuid);
                return true;
            }) || [];
        return [...rates_without_template, ...rates_with_templates].map((rate) => ({
            text: rate.rate_template ? rate.rate_template.name : rate.name,
            name: `${rate.rate_template ? 'rate_template' : 'rate'}:${rate.rate_template ? rate.rate_template.uuid : rate.id}`,
            key: `${rate.rate_template ? 'rate_template' : 'rate'}:${rate.rate_template ? rate.rate_template.uuid : rate.id}`
        }));
    }, [rates]);

    const optionsInventories = React.useMemo(() => {
        return inventories?.map((inventory) => ({
            text: inventory.name,
            name: inventory.uuid,
            key: inventory.uuid
        }));
    }, [inventories]);

    const onChangeAndFormatAttenteesDuplicated = React.useCallback((selected) => {
        let selectedFormatted = null;
        if (selected) {
            selectedFormatted = selected.map((attendee) => {
                let form = { ws_9: '' };
                if (attendee.form) {
                    let response = attendee.form.find((response) => response.weez_id === 'ws-9');
                    if (response) {
                        form = { ws_9: response.answer };
                    }
                }

                return {
                    ...emptyGuest(),
                    first_name: attendee.first_name,
                    last_name: attendee.last_name,
                    email: attendee.email,
                    form: form
                };
            });
        }
        setAttendeesDuplicated(selectedFormatted);
    }, []);

    if (loadingRates || loadingInventories || !hasHandledInventoriesLoaded.current || loadingPartnerForm) {
        return null;
    }

    if (error) {
        return `Error: ${error}`;
    }

    return (
        <>
            {waiting_guests ? (
                <Card className={Styles['waiting_guests__container']}>
                    <ContentCard className={Styles['waiting_guests__content']}>
                        <Loader className={Styles['loader-message-add']} active inline size={'tiny'} />
                        <span className={Styles['message-add']}>
                            {t('partners.invitation.table.waiting_guests_description', {
                                count: waiting_guests
                            })}
                        </span>
                    </ContentCard>
                </Card>
            ) : null}
            <Card separator={false} className={Styles['list-container']}>
                <HeaderCard>
                    <TitleCard title={t('partners.invitation.attendees_list')} />
                </HeaderCard>
                <ContentCard>
                    <DeleteAttendeeModal
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        handleDeleteAttendee={handleDeleteAttendee}
                        attendees={selectedAttendees}
                        partner={partner}
                        organizationId={organizationId}
                        isDeleting={isDeleting}
                    />
                    <AttendeeSideBar
                        attendee={attendeeQuickEdit}
                        onClickAway={() => setAttendeeQuickEdit(null)}
                        onSubmit={() => {
                            setAttendeeQuickEdit(null);
                            sync();
                        }}
                        partner={partner}
                        form={partnerForm}
                        language={userLanguage}
                        hasPartnerForm={hasPartnerForm}
                        mailStatusModel={mailStatusModel}
                        inventories={inventories}
                        rates={rates}
                        rateModel={rateModel}
                        bookingModel={bookingModel}
                        organizationId={organizationId}
                        partnerStatus={partner.status}
                    />
                    <AttendeeCreationDrawer
                        attendeesDuplicated={attendeesDuplicated}
                        onChangeAttendeesDuplicated={setAttendeesDuplicated}
                        partner={partner}
                        language={userLanguage}
                        hasPartnerForm={hasPartnerForm}
                        partnerForm={partnerForm}
                        openSideBar={addAttendee}
                        onCloseSideBar={() => {
                            setAddAttendee(false);
                            // reinitialize tab of attendee to duplicate
                            setAttendeesDuplicated(null);
                        }}
                        rateModel={rateModel}
                        batchStatsModel={batchStatsModel}
                        bookingModel={bookingModel}
                        onSuccess={(reinvite) => {
                            prefetchProgress();
                            // reinitialize tab of attendee to duplicate
                            setAttendeesDuplicated(null);
                            if (reinvite) {
                                setAddAttendee(reinvite);
                            }
                        }}
                        AttendeeCreationRequestModel={attendeeCreationRequestModel}
                        user={user}
                        InventoryModel={inventoryModel}
                    />
                    <FilterSetController
                        total={+attendees?.__meta?.headers?.get('total-count') || 0}
                        labelTotal={t('partners.common.guest', { count: +attendees?.__meta?.headers?.get('total-count') || 0 })}
                        labelReset={t('ticket.common.action.reset')}
                        labelFilters={t('partners.filters.button_filters')}
                        search={{
                            onChange: (synth, value) => {
                                onChangeSearch(value);
                            },
                            handleReset: (synth, value) => {
                                onChangeSearch(null);
                            },
                            displayResetIcon: true,
                            placeholder: t('ticket.common.action.search'),
                            value: filters?.search || ''
                        }}
                        onChange={(values) => onChangeFilters(values)}
                        initialValues={filters}
                        onReset={() => setFilters(filters?.search ? { search: filters?.search } : {})}
                    >
                        {optionsRates.length > 0 && (
                            <Filter
                                name={'rates'}
                                labelName={t('partners.filters.label_filter_rate')}
                                multiple
                                options={optionsRates}
                                index="1"
                                labelSelectAll={t('ticket.common.action.select-all')}
                                labelCTA={t('ticket.common.action.apply')}
                            />
                        )}
                        {hasHandledInventoriesLoaded && optionsInventories.length > 0 && (
                            <Filter
                                name="booking__inventory__in"
                                labelName={t('partners.filters.label_filter_inventory')}
                                multiple
                                options={optionsInventories}
                                index="2"
                                labelSelectAll={t('ticket.common.action.select-all')}
                                labelCTA={t('ticket.common.action.apply')}
                            />
                        )}
                        <Filter
                            name="event_time_status"
                            labelName={t('partners.filters.label_filter_event_time_status')}
                            multiple
                            options={[
                                {
                                    text: t('partners.filters.label_filter_event_time_status_upcoming'),
                                    name: 'upcoming',
                                    key: 1
                                },
                                {
                                    text: t('partners.filters.label_filter_event_time_status_ongoing'),
                                    name: 'ongoing',
                                    key: 2
                                },
                                {
                                    text: t('partners.filters.label_filter_event_time_status_over'),
                                    name: 'over',
                                    key: 3
                                }
                            ]}
                            index="3"
                            labelCTA={t('ticket.common.action.apply')}
                        />
                        <Filter
                            name="mail_sent"
                            labelName={t('partners.filters.label_filter_mail_sent')}
                            multiple
                            options={[
                                {
                                    text: t('partners.filters.label_filter_mail_sent_true'),
                                    name: 'True',
                                    key: 1
                                },
                                {
                                    text: t('partners.filters.label_filter_mail_sent_false'),
                                    name: 'False',
                                    key: 2
                                }
                            ]}
                            index="4"
                            labelCTA={t('ticket.common.action.apply')}
                        />
                    </FilterSetController>
                    <div className={Styles['global-pagesize-container']}>
                        <DropdownActionAttendee
                            selectedAttendees={selectedAttendees}
                            onSendMail={sendMail}
                            onDownloadTicket={downloadTicket}
                            onModify={setAttendeeQuickEdit}
                            setShowDeleteModal={setShowDeleteModal}
                            onReceiveMail={user && sendMail}
                            onChangeAttendeesDuplicated={onChangeAndFormatAttenteesDuplicated}
                            setAddAttendee={setAddAttendee}
                            googleAnalytics={googleAnalytics}
                            disableDeletionSelection={disableDeletionSelection}
                            setSelectedAttendees={setSelectedAttendees}
                            disabled={partner.status === 'deleted'}
                        />
                        {!loading && +attendees?.__meta?.headers?.get('total-count') > 51 && (
                            <DropdownPageSize
                                pageSize={pageSize}
                                onChange={(pageSize) => {
                                    setPageSize(pageSize);
                                }}
                                onChangeCurrentpage={setCurrentPage}
                                translation_key={'partners.invitation.page_size'}
                            />
                        )}
                    </div>
                </ContentCard>
                <Table useNew selectable onSelectAll={(event, data) => selectAll({ event, data })} className={Styles['list__table']}>
                    <Table.THead>
                        <Table.Tr isHeader className={Styles['tr-header']} active={selectedAll}>
                            <th className={Styles['width-x-large']}>{t('partners.invitation.table.headers.attendee')}</th>
                            <th className={Styles['width-large']}>{t('partners.invitation.creation.columns.header.enterprise')}</th>
                            <th className={Styles['width-x-large']}>{t('partners.invitation.table.headers.event')}</th>
                            <th className={Styles['width-x-large']}>{t('partners.invitation.table.headers.rate')}</th>
                            {partner.is_seated && (
                                <th style={{ textAlign: 'center' }} className={Styles['width-mini']}>
                                    {t('partners.invitation.table.headers.seating')}
                                </th>
                            )}
                            <th className={Styles['width-mini']}>{t('partners.invitation.table.headers.emails')}</th>
                            <th className={Styles['width-mini']}>{t('partners.invitation.table.headers.scanned')}</th>
                            <th className={Styles['width-mini']} style={{ textAlign: 'center' }}>
                                {t('partners.invitation.table.headers.actions')}
                            </th>
                        </Table.Tr>
                    </Table.THead>
                    {!loading && hasHandledInventoriesLoaded.current && (
                        <Table.TBody>
                            {attendees.map((attendee) => (
                                <AttendeeRow
                                    key={attendee.pk}
                                    partner={partner}
                                    attendee={attendee}
                                    onSelected={onAttendeeSelected}
                                    onDownloadTicket={downloadTicket}
                                    onSendMail={sendMail}
                                    selected={selectedAttendees.includes(attendee)}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        event.nativeEvent.stopImmediatePropagation();
                                        setAttendeeQuickEdit(attendee);
                                        googleAnalytics?.event({
                                            category: 'Guest list',
                                            action: 'View guest',
                                            label: 'View guest'
                                        });
                                    }}
                                    onDelete={handleDeleteAttendee}
                                    googleAnalytics={googleAnalytics}
                                    showDeleteModal={showDeleteModal}
                                    setShowDeleteModal={setShowDeleteModal}
                                    organizationId={organizationId}
                                    setSelectedAttendees={setSelectedAttendees}
                                >
                                    <Dropdown.Item
                                        item={t('partners.invitation.table.invite_again')}
                                        onClick={() => {
                                            setAddAttendee(true);
                                            onChangeAndFormatAttenteesDuplicated([attendee]);
                                            googleAnalytics?.event({
                                                category: 'Guest list',
                                                action: 'Menu actions - Invite Again',
                                                label: 'Invite Again'
                                            });
                                        }}
                                    />
                                    <Dropdown.Line />
                                    {attendee.send_mail && user && <Dropdown.Item item={t('partners.invitation.table.receive_mail')} onClick={() => sendMail([attendee], true)} />}
                                    <Dropdown.Item
                                        item={t('ticket.common.action.edit')}
                                        onClick={() => {
                                            setAttendeeQuickEdit(attendee);
                                            googleAnalytics?.event({
                                                category: 'Guest list',
                                                action: 'Menu actions - Edit guest',
                                                label: 'Edit guest'
                                            });
                                        }}
                                    />
                                </AttendeeRow>
                            ))}
                        </Table.TBody>
                    )}
                </Table>
                {loading && (
                    <div style={{ height: '20vh' }}>
                        <Loader active style={{ position: 'relative', marginTop: '20px' }}>
                            {t('partners.common.loading')}
                        </Loader>
                    </div>
                )}
                {!loading && attendees.length === 0 && <Card.EmptyState title={t('partners.invitation.no_guests')} icon={<Icon name="user" />} />}
                <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    <Paginator
                        currentPage={currentPage}
                        onPageChange={(numberPage) => {
                            setCurrentPage(numberPage);
                            setSelectedAttendees([]);
                        }}
                        totalCount={+attendees?.__meta?.headers?.get('total-count') || 0}
                        pageSize={pageSize}
                    />
                </div>
            </Card>
        </>
    );
}

AttendeesList.propTypes = {
    partner: PropTypes.object.isRequired,
    addAttendee: PropTypes.bool.isRequired,
    setAddAttendee: PropTypes.func.isRequired,
    onAttendeeInProgress: PropTypes.func,
    attendeeModel: PropTypes.func.isRequired,
    inventoryModel: PropTypes.func.isRequired,
    rateModel: PropTypes.func.isRequired,
    bookingModel: PropTypes.func.isRequired,
    attendeeCreationRequestModel: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    mailStatusModel: PropTypes.func.isRequired,
    googleAnalytics: PropTypes.object.isRequired,
    progressGuestsModel: PropTypes.func.isRequired,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
