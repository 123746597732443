import React from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

import { useTranslation } from 'react-i18next';

import { ToastContext, Card, ContentCard, Button, ToggleContainer, TextAreaLabel, StandardHeader } from '@weezevent/nacre';
import Styles from '../../../styles/components/invitations/attendee-creation-drawer.module.css';
import CardStyle from '../../../styles/components/invitations/edit-attendee-card.module.css';
import { default as SideBar } from '../portal';
import { SeatingInformationCard } from '../seatingCard';
import { GuestInformation } from './guestInformation/guestInformation';
import { emailValidation } from '../../../tooling';
import { InvitationsList } from '../invitationsList';

import { getAttendeeCreationInitialState, attendeeCreationReducer, getRateSelectionKey } from './state';

/**
 * Card of mail params
 * @param onCheckSendMail - func - On click on toggle
 * @param mailToggle - bool - Boolean of toggle to send mail
 * @param onChangeMailContent - func - on chang mail content
 * @param mailContent - string - mail content
 */
function MailCard({ onCheckSendMail, mailToggle, onChangeMailContent, mailContent, disabled }) {
    const { t } = useTranslation();

    return (
        <Card className={CardStyle['container']}>
            <Card.Header>
                <Card.Title title={t('partners.invitation.table.side_menu.mail.title')} />
            </Card.Header>
            <ContentCard>
                <ToggleContainer
                    mini
                    disabled={disabled}
                    className={Styles['toggle-block-container']}
                    toggle={mailToggle}
                    onToggle={(checked) => onCheckSendMail(checked)}
                    title={t('partners.invitation.attendees_quickadd.sendEmail')}
                />
                {mailToggle && (
                    <div className={Styles['textarea-block-container']}>
                        <TextAreaLabel
                            className={Styles['text_mail']}
                            label={t('partners.invitation.creation.mail.content_label')}
                            placeholder={t('partners.invitation.creation.mail.content_placeholder')}
                            value={mailContent}
                            onChange={(synth) => onChangeMailContent(synth.target.value)}
                        />
                    </div>
                )}
            </ContentCard>
        </Card>
    );
}
MailCard.propTypes = {
    onCheckSendMail: PropTypes.func,
    mailToggle: PropTypes.bool,
    onChangeMailContent: PropTypes.func,
    mailContent: PropTypes.string
};

/**
 * Drawer container.
 * @param attendees_obj - array - array of formated attendee to re invite them
 * @param inventories - array - contains all inventories
 * @param partner - obj - partner obj
 * @param onSave  - func - on save button clicked (passed to the footer)
 * @param onCancel - func - on cancel button clicked (passed to the footer)
 * @param rateModel - Model - Rate model
 * @param bookingModel - model - Booking model
 */
function DrawerContent({
    attendeesDuplicated = null,
    onChangeAttendeesDuplicated = () => {},
    inventories = [],
    partnerForm,
    hasPartnerForm,
    partner,
    onSave,
    onCancel,
    rateModel,
    bookingModel,
    stats,
    language
}) {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);
    const [sendMail, setSendMail] = React.useState({ sendMail: false, mailBody: '' });
    const [singleSelectionPossible, setSingleSelectionPossible] = React.useState(false);

    const [state, dispatch] = React.useReducer(attendeeCreationReducer, getAttendeeCreationInitialState(inventories));

    React.useEffect(() => {
        if (attendeesDuplicated) {
            let guestsDuplicated = attendeesDuplicated;
            onChangeAttendeesDuplicated(null);
            dispatch({ type: 'relaodGuestsForReinvite', guestsDuplicated });
        }
    }, [attendeesDuplicated, dispatch]);

    const ratesAlreadySelected = React.useMemo(() => {
        return Object.values(state.rateSelections)
            .map((selection) => {
                if (selection.rate) {
                    return selection.rate.id;
                }

                return null;
            })
            .filter((e) => e);
    }, [state]);

    const bookingsList = React.useMemo(() => {
        return Object.keys(state.rateSelections).map((obj) => state.rateSelections[obj].bookings);
    }, [state]);

    const selectedBookings = React.useMemo(() => {
        // get array of booking selected. Make filter to skip null.
        return Object.keys(state.bookingsUsed)
            .map((rsKey) => Object.keys(state.bookingsUsed[rsKey]))
            .flat();
    }, [state]);

    const attendeeCount = React.useMemo(() => {
        return state.guests.length * Object.keys(state.rateSelections).length;
    }, [state]);

    const hasEmailField = React.useMemo(() => {
        return Boolean(!state.anonymousMode && hasPartnerForm && partnerForm?.find((fd) => fd.form_field.weez_id === 'ws_5'));
    }, [partnerForm, hasPartnerForm, state]);

    const submitEnabled = React.useMemo(() => {
        // IsValid will be filled with Validation from guests data
        let isValid = true;

        state.guests.forEach((guest) => {
            if (hasEmailField && (!('ws_5' in guest.form) || guest.form['ws_5'] === '')) {
                isValid = false;
            }

            if (!hasPartnerForm && !guest?.email.length) {
                return;
            }

            if (!hasPartnerForm && (!guest?.first_name.length || !guest?.last_name.length)) {
                isValid = false;
            }

            let email = hasEmailField ? guest.form['ws_5'] : guest.email;

            if (email) {
                isValid = isValid && emailValidation(email);
            }

            if (partner.allow_no_named && email) {
                isValid = emailValidation(email);
            }
        });
        return attendeeCount > 0 && attendeeCount === selectedBookings.length && isValid;
    }, [state, attendeeCount, bookingsList, emailValidation, hasPartnerForm, hasEmailField, selectedBookings]);

    const onSubmit = React.useCallback(
        (reinvite = false) => {
            // Table case
            let errors = state.guests.map((attendee) => {
                return {
                    email: hasEmailField ? 'ws_5' in attendee.form && !emailValidation(attendee.form['ws_5']) : attendee.email.length && !emailValidation(attendee.email)
                };
            });

            let first_error = errors.findIndex((attendee) => attendee.email === true);
            if (first_error !== -1) {
                return toast.error(t('partners.invitation.table.side_menu.attendee.mail_error_with_line', { line: first_error + 1 }));
            }

            let attendeeObjects = [];

            Object.values(state.rateSelections).forEach((rs) => {
                Object.values(state.guests).forEach((guest) => {
                    if (hasPartnerForm) {
                        const keys = [
                            { key: 'ws_2', name: 'last_name' },
                            { key: 'ws_3', name: 'first_name' },
                            { key: 'ws_5', name: 'email' }
                        ];
                        keys.forEach((k) => {
                            if (k.key in guest.form) {
                                guest[k.name] = guest.form[k.key];
                                delete guest.form[k.key];
                            }
                        });
                    }
                    let _guest = { ...guest, booking: guest.bookings[rs.key].booking_uuid };
                    delete _guest.bookings;
                    attendeeObjects.push(_guest);
                });
            });

            if (attendeeCount !== attendeeObjects.length) {
                return toast.error(t('partners.invitation.creation.submit.error.not_all_guests_are_booked'));
            }

            onSave(attendeeObjects, { checked: sendMail.sendMail, mailContent: sendMail.mailBody, toMyself: false, auto_book: false }, reinvite);
        },
        [onSave, state, selectedBookings, t, toast, sendMail, attendeeCount, hasEmailField]
    );

    const disableEmailCard = React.useMemo(() => {
        // checks if events are selected or not, new_event corresponds to the default state value
        const events = Object.values(state.rateSelections);

        if ('new_event' in state.rateSelections && events.length < 2) {
            return false;
        }

        const hasDisabledInventories = events.some((ev) => !ev.inventory?.send_mail);

        if (hasDisabledInventories) {
            setSendMail({ ...sendMail, sendMail: false });
        }

        return hasDisabledInventories;
    }, [state]);

    return (
        <div>
            <GuestInformation
                state={state}
                hasPartnerForm={hasPartnerForm}
                partnerForm={partnerForm}
                guests={state.guests}
                language={language}
                onChangeGuests={(guests) => dispatch({ type: 'guests', guests })}
                noNameToggle={state.anonymousMode}
                onChangeNoNameToggle={(anonymousMode) => dispatch({ type: 'anonymousMode', anonymousMode })}
                quantity={state.guests.length}
                onChangeQuantity={(quantity) => dispatch({ type: 'quantity', quantity })}
                partner={partner}
            />
            {Object.keys(state.rateSelections).map((key) => (
                <SeatingInformationCard
                    key={key}
                    state={state}
                    keyRateSelection={key}
                    edit={false}
                    rateSelection={state.rateSelections[key]}
                    inventory={key !== 'new_event' ? state.rateSelections[key].inventory : null}
                    rate={state.rateSelections[key].rate}
                    partnerId={partner.pk}
                    inventories={inventories}
                    onChangeRateSelection
                    ratesAlreadySelected={ratesAlreadySelected}
                    displayFirstBooking={state.guests.length > 1}
                    onChange={(inventory, rate, zone, booking) => {
                        dispatch({ type: 'changeRateSelection', inventory, rate, zone, booking, key });
                    }}
                    onRetrieveBookings={(bookings, inventory, rate, isGA) => {
                        if (!bookings || !bookings.length) {
                            return;
                        }

                        dispatch({ type: 'onRetrieveBookings', bookings, inventory, rate, isGA });
                    }}
                    onDeleteInventory={(inventory, rate) => {
                        let _key = getRateSelectionKey({ inventory_uuid: inventory?.uuid, rate_id: rate?.id });
                        dispatch({ type: 'deleteRateSelection', key: _key });
                    }}
                    rateModel={rateModel}
                    bookingModel={bookingModel}
                    isManual={state.rateSelections[key].is_manual}
                    onSingleSelectionPossible={setSingleSelectionPossible}
                />
            ))}

            {!singleSelectionPossible && (
                <div className={Styles['button-add-event']}>
                    <Button
                        label={t('partners.invitation.table.side_menu.cta.add_event')}
                        fluid={false}
                        primary
                        inverted
                        align={'right'}
                        onClick={() => {
                            dispatch({ type: 'addDefaultRateSelection', inventories: inventories });
                        }}
                    />
                </div>
            )}

            <MailCard
                disabled={disableEmailCard}
                onCheckSendMail={(check) => setSendMail({ ...sendMail, sendMail: check })}
                mailToggle={sendMail.sendMail}
                onChangeMailContent={(content) => setSendMail({ ...sendMail, mailBody: content })}
                mailContent={sendMail.mailBody}
            />

            <InvitationsList state={state} dispatch={dispatch} invitationsCount={attendeeCount} partnerForm={partnerForm} hasPartnerForm={hasPartnerForm} />
            <FooterSideBar onSubmit={onSubmit} onCancel={onCancel} attendeeCount={attendeeCount} submitEnabled={submitEnabled} />
        </div>
    );
}

DrawerContent.propTypes = {
    attendeesDuplicated: PropTypes.array,
    onChangeAttendeesDuplicated: PropTypes.func,
    partner: PropTypes.object,
    inventories: PropTypes.array,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    rateModel: PropTypes.any,
    bookingModel: PropTypes.any
};

function FooterSideBar({ onSubmit, onCancel, attendeeCount, submitEnabled }) {
    const { t } = useTranslation();

    return (
        <div className={Styles['footer-button-container']}>
            <Button.Group className={Styles['footer-button-group']}>
                <Button primary label={t('partners.invitation.table.side_menu.cta.add', { count: attendeeCount })} onClick={() => onSubmit(false)} disabled={!submitEnabled} />
                <Button
                    className={Styles['add-and_continue-button']}
                    inverted
                    primary
                    label={t('partners.invitation.table.side_menu.cta.addAndContinue', { count: attendeeCount })}
                    onClick={() => onSubmit(true)}
                    disabled={!submitEnabled}
                />
                <Button label={t('ticket.common.action.cancel')} onClick={onCancel} />
            </Button.Group>
        </div>
    );
}
FooterSideBar.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

/**
 * Drawer to create attendees
 * @param attendeesToDuplicate - array - List of attendees object to re invite
 * @param partner - obj - partner obj
 * @param onSuccess - func - Action to call on sucess of save
 * @param openSideBar  - boolean - Open or close drawer
 * @param onCloseSideBar - func - on close drawer
 * @param rateModel - Model - Rate model
 * @param bookingModel - model - Booking model
 */
export function AttendeeCreationDrawer({
    attendeesDuplicated = null,
    onChangeAttendeesDuplicated = () => {},
    partner,
    partnerForm,
    hasPartnerForm,
    onSuccess = () => {},
    openSideBar = false,
    onCloseSideBar,
    rateModel,
    bookingModel,
    batchStatsModel,
    AttendeeCreationRequestModel,
    user,
    language,
    InventoryModel
}) {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);

    const [inventories, loading] = InventoryModel.useApiModel(
        {
            partnerId: partner.pk
        },
        [partner]
    );

    const [stats, loadingStats] = batchStatsModel.useApiModel(
        {
            id: partner.pk,
            partnerId: partner.pk,
            getAll: false,
            launch: openSideBar, // launch query on open drawer
            onError: (e) => {
                Sentry.captureException(e);
            }
        },
        [openSideBar]
    );

    const statsPerRates = React.useMemo(() => {
        if (!stats) {
            return [];
        }

        let tmpArray = [];
        stats.inventories.forEach((inventory) => {
            tmpArray = tmpArray.concat(inventory.rates);
        });
        return tmpArray;
    }, [stats]);

    const handleSubmitInvitations = React.useCallback(
        (attendees_dict = [], { checked = false, mailContent = null, toMyself = false, auto_book = false } = {}, reinvite = false) => {
            let params = {};
            if (checked) {
                params = { ...params, mail_body: mailContent, send_mail: true };
            }
            if (toMyself) {
                params['to'] = { name: user.name, email: user.email };
            }
            let attendeeRequest = new AttendeeCreationRequestModel({ attendees: attendees_dict, auto_book: auto_book, send_mail: false, ...params });
            attendeeRequest
                .save({
                    partnerId: partner.pk
                })
                .then(() => {
                    toast.success(t('partners.invitation.creation.success'));
                    onCloseSideBar();
                    onSuccess(reinvite);
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    const error = e.parseErrorRecursive(t, 'partners.invitation.attendees_quickadd.');
                    if (error) {
                        const split = error.split('\n');
                        const error_content = [];
                        error_content.push(t('ticket.partners.batch.creationFailureList'), <br />);
                        for (let line of split) {
                            error_content.push(line, <br />);
                        }
                        return toast.error(error_content);
                    }
                    return toast.error(t('partners.invitation.creation.error'));
                });
        },
        [partner, toast, t, user, onCloseSideBar]
    );

    if (loading || loadingStats) {
        return null;
    }

    return (
        <SideBar className={Styles['drawer']} open={openSideBar} onClickAway={() => {}} onClose={onCloseSideBar} size={'large'}>
            <StandardHeader title={t('partners.invitation.table.side_menu.stats.title')} />
            <DrawerContent
                attendeesDuplicated={attendeesDuplicated}
                onChangeAttendeesDuplicated={onChangeAttendeesDuplicated}
                inventories={inventories}
                partner={partner}
                language={language}
                partnerForm={partnerForm}
                hasPartnerForm={hasPartnerForm}
                onSave={(attendees_dict, params, reinvite) => handleSubmitInvitations(attendees_dict, params, reinvite)}
                onCancel={onCloseSideBar}
                rateModel={rateModel}
                bookingModel={bookingModel}
                stats={statsPerRates}
            />
        </SideBar>
    );
}

AttendeeCreationDrawer.propTypes = {
    attendeesDuplicated: PropTypes.array,
    onChangeAttendeesDuplicated: PropTypes.func,
    partner: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    openSideBar: PropTypes.bool,
    onCloseSideBar: PropTypes.func,
    rateModel: PropTypes.any,
    bookingModel: PropTypes.any
};
